@font-face {
    font-family: "Nekst";
    src: url(../../assets/fonts/Nekst-Light.otf);
    font-weight: 400;
}
@font-face {
    font-family: "Nekst";
    src: url(../../assets/fonts/Nekst-Regular.otf);
    font-weight: 500;
}
@font-face {
    font-family: "Nekst";
    src: url(../../assets/fonts/Nekst-Bold.otf);
    font-weight: 600;
}
// @font-face {
//     font-family: 'Gilroy';
//     src: url('../../assets/fonts/Gilroy-SemiBold.woff2') format('woff2'),
//         url('../../assets/fonts/Gilroy-SemiBold.woff') format('woff');
//     font-weight: 600;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../../assets/fonts/Gilroy-Thin.woff2') format('woff2'),
//         url('../../assets/fonts/Gilroy-Thin.woff') format('woff');
//     font-weight: 100;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../../assets/fonts/Gilroy-Bold.woff2') format('woff2'),
//         url('../../assets/fonts/Gilroy-Bold.woff') format('woff');
//     font-weight: 700;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../../assets/fonts/Gilroy-Medium.woff2') format('woff2'),
//         url('../../assets/fonts/Gilroy-Medium.woff') format('woff');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../../assets/fonts/Gilroy-Regular.woff2') format('woff2'),
//         url('../../assets/fonts/Gilroy-Regular.woff') format('woff');
//     font-weight: 400;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../../assets/fonts/Gilroy-Light.woff2') format('woff2'),
//         url('../../assets/fonts/Gilroy-Light.woff') format('woff');
//     font-weight: 300;
//     font-style: normal;
//     font-display: swap;
// }



.nekst-font {
    font-family: "Nekst", sans-serif;
}

* {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
    font: inherit;
}

ul, li {
    margin: 0;
    padding: 0;
}


li {
    list-style: none;
}
html {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 100%;
}
.ant-upload-picture-card-wrapper {
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 18px;
    //font-weight: normal;
    color: var(--violet);
    background-color: var(--bg);
    overflow-x: hidden !important;
    // font-display: swap;
    // -webkit-font-smoothing: antialiased;
}
#root {
    // height: 100%;
    display: flex;
    overflow-x: hidden;

}
.page {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-x: hidden;

    .Header {

    }

    .Main {
        flex: 1 0 auto;
        height: 100%;
    }
}

.pageBody {
    display: flex;
    height: 100%;
    padding-top: 80px;
    overflow-x: hidden;
}

.pageBody-content {
    padding: 20px;
    width: 100%;
    overflow-x: hidden;
}


.def-label {
    font-weight: 600;
    color: #989898;
    margin-bottom: 10px;
}

.part {
    width: 100%;
}

.row-custom {
    margin-bottom: 20px;
}
.def-label {
    font-size: 16px;
    line-height: 19px;
    color: #989898;
}
.def-value {
    font-size: 16px;
    line-height: 19px;
    color: var(--violet);
    font-weight: 600;
}

.draggable {
    border: 2px solid transparent;
    transition: all .3s ease;
    border-radius: 10px;

    // &:hover {
    //     border-color: var(--light_violet);
        
    // }
}

.draggable.dragStart {
    opacity: 0 !important;
    
}

.draggable.dragOver {
    border-left-color: var(--light_violet);
    border-style: dashed !important;
    opacity: .5;
}


.ddd {
    // height: 1000px;
    display: flex;


    &__item {
        padding: 10px;
    }

    &__item.ddd__item-ds {
        
    }

}



// .df {
//     display: flex;
// }
// .jcsb {
//     justify-content: space-between;
// }
// .jcc {
//     justify-content: center;
// }
// .aic {
//     align-items: center;
// }
// body {
//     background-color: #fafafa;
//     font-family: "Gilroy", sans-serif !important;
// }
