.DropCollapse.shadow {
    box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);
}

.DropCollapse.justifyLeft {
    .DropCollapse__head {
        &_value {
            justify-content: flex-start;

            &_el {
                margin-left: 10px;
            }
        }
    }
}

.DropCollapse.justifyRight {
    .DropCollapse__head {
        
        &_value {
            justify-content: flex-end;

            
        }
    }
}

.DropCollapse.load {
    pointer-events: none;
    touch-action: none;
    

}
.DropCollapse {
    border-radius: 10px;
    background-color: #fff;
    width: 100%;
    padding: 14px 20px;
    padding-bottom: 0;
    // margin-bottom: 15px;
    position: relative;

    &__load{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
        font-size: 20px;
        color: var(--violet);
        background-color: rgba(#fff, .7);
    }
    

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        font-weight: 600;
        padding-bottom: 14px;
        position: relative;

       
        
        
        &_label {
            color: rgba(123, 153, 255, 0.6);    
            width: 100%;
            padding-right: 10px;
            width: 100%;
        }

        &_value {
            width: 100%;
            color: var(--violet);
            display: flex;
            align-items: center;
            justify-content: space-between;

            &_el {
                
            }

            &_icon {
                line-height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                // margin-left: 10px;
                transition: all .3s ease;
            }
        }
    }

    &__list {
        overflow: hidden;
        transition: all .3s ease;
        //border-top: 1px solid var(--light_violet);

        &_el {
            
        }

        &_ex {
            padding-bottom: 20px;
        }
        
    }



    &__item.active {

        &:after {
            transform: scale(1);
        }
    }

    &__item {
        padding: 12px;
        padding-left: 24px;
        cursor: pointer;
        position: relative;

        &:after {
            width: 12px;
            height: 12px;
            position: absolute;
            top: calc(50% - 6px);
            left: 0;
            border-radius: 100%;
            background-color: var(--light_violet);
            content: '';
            transition: all .3s ease;
            transform: scale(0);
        }

        // &:last-child {
        //     padding-bottom: 30px;
        // }
    }


}

.DropCollapse.active {
    .DropCollapse__head {

        &_value {

            &_icon {
                transform: rotate(180deg);
            }
        }
    }
}

.DropCollapse.afterIcon {

    

}