.Header {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    &__in {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
    }

    &__logo {
        width: 60px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: flex;
        }
    }
}