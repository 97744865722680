.MiniOrg {
    height: 90px;
    display: flex;  
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;

    &__img {
        height: 100%;
        overflow: hidden;
        max-height: 66px;
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: flex;
        }
    }

    &__name {
        padding: 5px;
        flex: 0 0 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        line-height: 14px;
        color: #666;
        font-weight: 500;
    }
}