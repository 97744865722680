.wrapper {
  width: 65px;
  height: 65px;
  cursor: pointer;
  transition: all .2s ease;
  position: relative;
  border: none;
  background-color: unset;

  &:active {
    transform: scale(0.9);
  }
  
  .main {
    width: 100%;
    height: 100%;
    background-color: var(--violet);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 35px;
    line-height: 35px;
    color: #fff;

  }

  .icon {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: absolute;
    bottom: 0;
    right: -16px;
    background-color: #2A2A2A;
  }


}