.CatCard {
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    height: 260px;

    user-select: none;
    
    &__img {
        height: 180px;
        flex: 0 0 auto;
        pointer-events: none;
        touch-action: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: flex;
        }
    }

    &__body {
        padding:15px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 0 auto;

        
        
        &_name {
            font-weight: 500;
            color: #666;
            overflow: hidden;
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            

            &_el {

            }

            // &_grad {
            //     position: absolute;
            //     bottom: 0;
            //     left: 0;
            //     height: 20px;
            //     width: 100%;
                
              
            //     background: linear-gradient(0deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0.22314863445378152) 100%);
            //     content: '';
                
            // }
        }

        &_price {
            font-weight: 600;
            // margin-top: 15px;
            &_actual {
                color:#FC5834;
            }

            &_main {
                color: #666;
                font-size: 14px;
                line-height: 17px;
                text-decoration: line-through;
            }
        }
    }
}