.AddStorie {

    &__add {
        
    }

    &__images {
        position: relative;
        overflow: hidden;
        
        &_loader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            transition: all .3s ease;
            opacity: 0;
            background-color: rgba(#fff, .5);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &_loader.active {
            z-index: 3;
            opacity: 1;
        }
    }
    &__pim {
        width: 154px;
        height: 170px;
        position: relative;

        &_img {
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 10px;box-shadow: 0px 0px 41px rgb(123 153 255 / 15%);

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                display: flex;
            }
        }

        &_action {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }


    }

    .react-thumbnail-generator {
        width: 200px;
        height: 200px;
        flex: 0 0 auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}