.ReservItemModal {

    &__body {
        padding: 20px;
        border-radius: 15px;
        background: #FFFFFF;
        box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);

        &_item {
            font-size: 18px;
            line-height: 24px;
            font-weight: 600;
            &_label {
                margin-bottom: 10px;
                color: var(--gray);
            }

            &_value {
                color: var(--violet);
            }
        }
    }
}