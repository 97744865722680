.StorieCatItem {
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    width: 270px;
    box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);
    width: 100%;
    
    &__img {
        width: 100px;
        height: 100px;
        border-radius: 10px;
        overflow: hidden;
        flex: 0 0 auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: flex;
        }
    }

    &__name {
        padding-left: 20px;
        font-weight: 500;
        color: #666;
    }
}