.MiniBrand {
    height: 90px;
    display: flex;
    flex-direction: column;

    &__img {
        border-radius: 10px;
        margin-bottom: 4px;
        // flex: 1 0 auto;
        overflow: hidden;
        background-color: #fff;
        

        img {
            width: 100%;
            height: 100%;  
            object-fit: contain;
            display: flex;
        }
    }

    &__action {
        flex: 0 0 auto;

        .Button {
            width: 100%;
            padding: 10px;
            border-radius: 10px;

            &__text {
                font-size: 12px;
                line-height: 12px;
            }
        }
    }
}