.Notfound {
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    
    &__icon {
        width: 400px;
        height: 400px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: flex;
        }
    }
    &__text {
        font-size: 25px;
        line-height: 30px;
        font-weight: 600;
        color: #000;
    }

    &__action {
        margin-top: 30px;
    }
}