
.ArticlePage__textarea {
  border: none;
  color: #8da6f8;
  font-weight: 600;
  font-size: 17px;
  padding: 10px 20px;
  border: 1.5px solid rgba(176, 194, 255, .6);
  border-radius: 15px;
}
.QAPage__textarea::placeholder  {
  font-weight: 600;
  font-size: 17px;
  color: #B0C2FF;
}
.QAPage__textarea:focus {
  border-color: #B0C2FF;
  border: 2px solid rgba(176, 194, 255, .6);
}

.ArticlePage {
  &__button {
    display: flex;
    justify-content: flex-end;
  }
  &__modal {
    &-img {
      border: 2px dashed #333;
      width: 40%;
      margin-bottom: 20px;
    }
    &-descr {
      font-weight: 500;
      font-size: 17px;

      img {
        width: 100%;
      }
    }
    &-wrapper {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
      column-gap: 20px;
    }
    &-text {
      font-size: 22px;
      margin-bottom: 20px;
      font-weight: 600;
      border-bottom: 2px solid #eee;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    &-label {
      padding-top: 10px;
      width: 50%;
      white-space: nowrap;
      font-size: 16px;
                    margin-right: 20px;
                    font-weight: 600;
                    color: var(--violet);
    }
    &-pic {
      img {
        border-radius: 15px;
      }
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 30px;
  }
}

.previewText {
  color: black; 
  margin-top: 10px; 
  padding: 0 20px;
  ul {
      margin-bottom: 20px;
  }
  li {
      list-style: unset;
      margin-left: 27px;
  }
  strong, h1 {
      line-height: 34px;
  }
}