.PlUpload {
    position: relative;

    input {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        pointer-events: none; 
    }

    label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

