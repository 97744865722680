.Pl-layer {
    position: relative;
    height: 100%;
    // overflow: hidden;
    border-radius: 10px;
    width: 100%;
}

.Pl {
    padding: 15px 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #989898;
    font-weight: 600;
    height: 100%;
    width: 100%;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all .3s ease;
    overflow: hidden;
    

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: flex;
    }

    &:active {
        transform: scale(0.95);
    }

    &:hover {
        border-color: var(--light_violet);
    }

    &__delete {
        position: absolute;
        top: 12px;
        right: 8px;
        background-color: var(--red);
        color: #fff;
        font-size: 17px;
        line-height: 17px;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        transition: all .2s ease;
        cursor: pointer;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &:active {
            transform: scale(0.95);
        }
    }
}

.Pl.shadow {
    box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);
}

.Pl.nopadding {
    padding: 0;
}