.TrashItem {
    width: 150px;
    // border: 1px solid red;

    button {
        width: 100%;
    }

    &__name {
        background-color: #fff;
        border-radius: 10px;
        height: 100px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        overflow: hidden;
        padding: 5px;
    }
}