
.vacanciesPage__textarea {
  border: none;
  color: #8da6f8;
  font-weight: 600;
  font-size: 17px;
  padding: 10px 20px;
  border: 1.5px solid rgba(176, 194, 255, .6);
  border-radius: 15px;
}
.vacanciesPage__textarea::placeholder  {
  font-weight: 600;
  font-size: 17px;
  color: #B0C2FF;
}
.vacanciesPage__textarea:focus {
  border-color: #B0C2FF;
  border: 2px solid rgba(176, 194, 255, .6);
}
.VacanciesPage {
  &__header {
    display: flex;
    justify-content: flex-end;
  }
  &__modal {
    &-descr {
      font-weight: 500;
      font-size: 17px;
    }
    &-wrapper {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
      column-gap: 20px;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    &-label {
      padding-top: 10px;
      width: 50%;
      white-space: nowrap;
      font-size: 16px;
                    margin-right: 20px;
                    font-weight: 600;
                    color: var(--violet);
    }
  }
}