.User {

    &__name {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        // margin-bottom: 20px;


        &_badge {
            padding: 5px 10px;
            border-radius: 30px;
            background: linear-gradient(180deg, #7B99FF 0%, #577DFC 100%);
            color: #fff;
            margin-left: 10px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            line-height: 14px;
        }
    }

    &__body {
        font-size: 16px;
        line-height: 18px;
        color: var(--violet);
        
        &_info {

            &_item {
                display: flex;
                align-items: center;
                // margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }

                &_label {
                    // white-space: nowrap;
                    width: 140px;
                    margin-right: 20px;
                    flex: 0 0 auto;
                    font-weight: 600;
                    color: var(--violet);
                }
            }
        }

        &_discount {
            display: flex;
            align-items: center;
            padding: 20px 0;
            justify-content: space-between;

            &_label {
                flex: 0 0 auto;
                font-weight: 600;
                margin-right: 25px;
            }

            &_item {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;

                &_value {
                    color: var(--light_violet);
                    font-weight: 600;
                    margin-right: 30px;
                }
            }
        }

        &_list {
        //    margin-bottom: 10px;

            &_head {
                
                font-weight: 600;
                color: #989898;
            }

            &_in {
                display: flex;
                overflow-x: auto;
                padding: 15px 0 20px;

            }

            &_item {
                flex: 0 0 auto;
                margin-right: 15px;
            }
        }
    }

    &__action {
        display: flex;

        
    }
}

.inputDisabled {
    input {
        background: #f3f3f3;
        border: 1px solid #d8d8d8;
    }
}