// .OrgsCreatePage {
//   padding-bottom: 80px;
// }
// .panel-df {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 15px;
// }
// .panel__left-text {
//   font-weight: 500;
//   font-size: 20px;
//   color: #303030;
//   margin-bottom: 0;
// }
// .panel__right {
//   display: flex;
//   cursor: pointer;
// }
// .panel__right-button {
//   font-family: "Gilroy", sans-serif;
//   font-weight: 500;
//   font-size: 15px;
//   letter-spacing: 0.04em;
//   text-transform: uppercase;
//   color: #3f80ff;
//   margin-right: 10px;
// }
// .panel__right-plus {
//   font-size: 35px;
//   font-weight: 400;
// }
// .orgs__details {
//   font-family: "Gilroy", sans-serif;
//   font-weight: 500;
//   font-size: 22px;
//   color: #303030;
//   margin-bottom: 0px;
// }
// .panel-label {
//   color: var(--dark-new);
// }
// .bottom-panel {
//   border-bottom: 1px solid #f5f5f5;
//   border-left: 1px solid #f5f5f5;
//   border-right: 1px solid #f5f5f5;
//   border-radius: 0 0 6px 6px;
//   background-color: #fff;
//   bottom: 0;
//   position: fixed;
//   z-index: 100;
//   width: 81.5%;
//   padding: 10px;
// }
// .polygon__top {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 20px;

//   p {
//     font-weight: 500;
//     font-size: 22px;
//     color: #303030;
//     margin-bottom: 0;
//   }
// }

// .payment__title {
//   margin-bottom: 20px;
//   font-weight: 500;
//   font-size: 22px;
//   color: #303030;
// }
// .panel__addImage {
//   position: absolute;
//   width: 120px;
//   top: 0;
//   opacity: 0;
//   left: 0;
//   // visibility: hidden;
//   // pointer-events: none; 
// }
// .panel__img-box {
//   position: relative;

//   .panel__img-delete {
//     position: absolute;
//     top: 4%;
//     right: 2%;
//     padding: 7px;
//     background-color: #fff;
//     border-radius: 50%;
//     cursor: pointer;
//   }
// }

.select_new {
    .ant-select-selector {
        border-radius: 10px !important;
        height: 46px !important;
        display: flex;
        align-items: center;
        border: none;
    }
    width: 100%;
}