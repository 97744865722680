.OrderPlate {
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);
    background-color: #fff;
    margin-bottom: 15px;

    &__main {
        display: flex;
        margin-bottom: 15px;

        &_img {
            width: 80px;
            height: 80px;
            border-radius: 10px;
            overflow: hidden;
            flex: 0 0 auto;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: flex;
            }
        }

        &_info {
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &_top {

                &_name {
                    font-weight: 600;
                    color: #666666;
                }

                &_ms {
                    color: rgba(102, 102, 102, 0.5);
                    font-weight: 500;
                }
            }

            &_price {
                color: var(--red);
                font-weight: 600;
            }
        }
    }

    &__body {

        &_part {
            font-weight: 500;
            margin-bottom: 15px;

            &_name {
                color: #989898;
                margin-bottom: 5px;
            }

            &_value {
                color: var(--violet);
            }
        }
    }
}