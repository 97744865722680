.wrapper {
  position: relative;
  // width: 230px;
  width: 350px;

  .action {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 2;
    width: 40px;
    
    .btn {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      line-height: 20px;
      border: none;
      cursor: pointer;
      transition: all .2s ease;

      &:active {
        transform: scale(0.95);
      }
    }

    .btn.btn_edit {
      background-color: #484848;
      color: #fff;

      &:hover {
        background-color: #000;
      }
    }
    .btn.btn_delete {
      background-color: #F7D9DE;
      color: #DC5E73;

      &:hover {
        background-color: red;
        color:#fff;
      }
    }
  }

  .main {
    position: relative;
    .timeline {
      position: absolute;
      top: 0;
      left: 0;
      padding: 5px 10px;
    }

    .prev {
      border-radius: 14px;
      background-color: #000;
      overflow: hidden;
      height: 635px;
      // height: 418px;
      width: 100%;
      position: relative;

      .prev_text {
          
      }

      .prev_mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      img, video {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: flex;
      }
    }
  }
}