.Button {
    cursor: pointer;
    border: none;
    display: flex;
    position: relative;
    
    align-items: center;
    padding: 15px;
    // min-width: 312px;
    color: #fff;
    transition: all .3s ease;
    border-radius: 10px;
    // font-size: 22px;
    // line-height: 27px;

    &:hover {
        opacity: .8;
    }

    &:active {
        transform: scale(0.95);
    }

    &:disabled {
        pointer-events: none;
        touch-action: none;
        
        opacity: .5;
    }

    &__load {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;


        &_icon {
            color: #fff;
        }
        
    }

    &__before {
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 100%;
    }

    &__text {
        font-weight: 600;
    }

    &__after {
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 100%;
    }
}

.Button.default {
    background-color: var(--violet);
}

.Button.danger {
    background-color: var(--red);
}

.Button.light {
    background-color: #fff;
    border: none;

    .Button__text {
        color: var(--violet);
    }

    &:hover {
        background-color: #fff;
        opacity: 1;
        .Button__text { 
            color: var(--violet);
        }
    }
}