:root {
    --bg: #F6F8FF;
    --light_gray: #F8F8F8;
    --gray: #7B7C80;
    --light_violet: #B0C2FF;
    --placeholder: #989898;
    --violet: #7B99FF;
    // --violet: #3F80FF;
    --red: #FF4A4A;
    --dark-new: #303030;
    --gray-new: #8a8a8a;
}