.OrderInfo {
    position: relative;

    

    &__main {
        &:first-child {
            box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);
        }
        &_item {
            margin-bottom: 20px;
            font-weight: 600;

            &_name {
                margin-bottom: 5px;
                color: #989898;
            }

            &_value {
                color: var(--violet);
            }
        }

        
    }
}


.logs {
    &__list {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}

.logItem {
    border: none;
    background: none;
    text-align: start;
    display: block;
    cursor: pointer;
    padding-bottom: 8px;
    border-bottom: 1px solid #b9b9b9;
    &__date {
        color: #989898;
    }
    &__action {
        color: var(--violet);
    }
    &__response {
        max-height: 200px;
        overflow-y: auto;
    }
}