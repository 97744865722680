.PicItem {
    height: 200px;
    width: 150px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    &__img {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: flex;
        }
    }

    &__action {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        .Button {
            width: 100%;
        }
    }


}