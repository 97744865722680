.Loader {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    line-height: 100%;

    span {
        
    }
}