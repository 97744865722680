.SelectColor {
    display: flex;
    align-items: center;

    &__input {
        margin-right: 20px;
        box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);
        border-radius: 100%;
        position: relative;
        input {
            position: absolute;
            top: 0;
            left: 0;
            visibility: hidden;
            z-index: -1;
            pointer-events: none;
        }
    }

    &__label {
        width: 50px;
        height: 50px;
        border: 5px solid #fff;
        
        border-radius: 100%;
        display: block;
        
    }

    &__value {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 25px;
    }
}