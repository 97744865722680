.DropSelect {
    padding: 14px 20px 0 20px;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    transition: all .3s ease;
    font-weight: 600;

    &__head {
        padding-bottom: 14px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
        // line-height: 19;
        color: var(--violet);
        cursor: pointer;

        &_icon {
            flex: 0 0 auto;
            transition: all .3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 100%;
        }

        &_value {
            margin-left: 10px;
        }
    }

    &__body_wrapper {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        height: 0;
    }

    &__body {
        box-shadow: 0px 30px 41px rgba(123, 153, 255, 0.2);
        margin-top: -5px;
        max-height: 300px;
        overflow-y: auto;
        background-color: #fff;
        border-radius: 0 0 20px 20px;
        position: fixed;
        z-index: 10001;
        
        transition: all .3s ease;

        &_item {
            padding:15px;
        }
    }
}

.DropSelect.shadow {

}

.DropSelect.is-open {
    border-radius: 10px 10px 0 0;

    .DropSelect__body_wrapper {
        height: 100%;
    }
    
    .DropSelect__head {
        &_icon {
            transform: rotate(180deg);
        }
    }
}