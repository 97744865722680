.RevInfo {

    &__body {
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);
        border-radius: 21px;
    }

    &__value {
        font-weight: 600;
        color: var(--violet);
        font-size: 18px;
        line-height: 25px;
    }
}