.BonusesPage {
  &__bottom {
    margin-top: 40px;
    display: flex;
    align-items: center;
  }
  &__text {
    font-size: 20px;
    color: #666;
    font-weight: 700;
    margin-bottom: 0;
    margin-right: 30px;
  }
}