.PeriodDate {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    top: 0;
    left: 0;
    
    &__in {
        position: fixed;
        background-color: #fff;
        padding: 30px;
        border-radius: 10px;
        box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.3);
        z-index: 1000000;
        width: 800px;
    }


    &__back {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &_btn {
            font-weight: 500;
            color: var(--placeholder);
            border: none;
            background-color: unset;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: all .3s ease;

            &_icon {
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 100%;
                
            }

            &:hover {
                color: var(--violet);
            }
        }
    }

    &__head {
        font-weight: 600;
        color: #000;
    }

    &__cal {

        &_el {
            button {
                border: none;
                background-color: unset;
            }

            .react-calendar__navigation {
                display: flex;
                align-items: center;
                justify-content: center;
                // margin-bottom: 10px;

                .react-calendar__navigation__prev-button {
                    padding: 5px 15px;
                    padding-bottom: 10px;
                    //border: 1px solid red;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 100%;
                    font-size: 25px;
                    cursor: pointer;
                }
                .react-calendar__navigation__next-button {
                    padding: 5px 15px;
                    padding-bottom: 10px;
                    //border: 1px solid red;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 100%;
                    font-size: 25px;
                    cursor: pointer;
                }
                .react-calendar__navigation__prev2-button {
                    display: none;
                }
                .react-calendar__navigation__next2-button {
                    display: none;
                }
                .react-calendar__navigation__label {
                    flex-grow: 0 !important;
                    margin: 0 20px;
                    font-weight: 600;
                    text-transform: capitalize;
                    pointer-events: none;
                    touch-action: none;
                }
            }

            .react-calendar__viewContainer {
                .react-calendar__month-view {

                    .react-calendar__month-view__weekdays {

                        .react-calendar__month-view__weekdays__weekday {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 15px;
                            abbr {
                                text-decoration: none;
                                text-transform: capitalize;
                                font-weight: 600;
                            }
                        }
                    }
                    .react-calendar__month-view__days {

                        .react-calendar__month-view__days__day {
                            padding: 10px 15px;
                            color: #7B7B7B;
                            font-weight: 600;
                        }
                        .react-calendar__month-view__days__day--neighboringMonth {
                            color: #D7D7D7;
                        }
                    }
                    .react-calendar__tile {
                        border-radius: 10px;
                        transition: all .2s ease;
                    }
                    .react-calendar__tile--active {
                        abbr {
                            color: #fff;
                        }
                        background-color: var(--violet);
                    }
                }
            }
        }
    }

    .rdrDateRangePickerWrapper {
        width: 100%;

        .rdrDateDisplayWrapper {
            display: none;
        }

        .rdrDefinedRangesWrapper {
            display: none;
        }
        .rdrDateRangeWrapper {
            width: 100%;

            .rdrMonthsHorizontal {
                margin-left: -10px;
                margin-right: -10px;
                width: 100%;

                .rdrMonth {
                    width: calc((100% / 2) - 20px);
                    margin: 0 10px;

                    .rdrMonthName {
                        text-align: center;
                        text-transform: capitalize;
                        font-size: 16px;
                        line-height: 20px;
                        color: var(--violet);
                    }
                    .rdrWeekDays {

                        .rdrWeekDay {
                            text-transform: capitalize;
                            font-weight: 600;
                        }
                    }
                    .rdrDays {

                        .rdrDay {
                            height: 45px;
                            span {
                                font-weight: 600;
                                // color: #7B7B7B;
                            }
                            .rdrStartEdge  {
                                color: var(--violet) !important;
                                
                            }
                            .rdrDayStartPreview  {
                                border: none;
                            }
                            .rdrInRange {
                                color: var(--light_violet) !important;
                            }
                            
                            .rdrEndEdge {
                                color: var(--violet) !important;
                            }
                        }

                        .rdrDayToday  {
                            
                            .rdrDayNumber {
                                // border: 3px dashed var(--light_violet);
                                // border-radius: 15px;
                                span {
                                    &:after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .rdrMonthAndYearWrapper {
                height: unset;

                .rdrMonthAndYearPickers {
                    display: none;
                }
            }
        }
        // .rdrMonthAndYearWrapper {
        //     display: none;
        // }
    }
}
