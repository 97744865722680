.panel {
    padding:15px;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;

    &-label {
      font-weight: 600;
      color: var(--gray);  
      margin-bottom: 25px;
    }
}

.panel.shadow {
  box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);
}