.MapPolygonPic {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__img {
        overflow: hidden;
        border-radius: 8px;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: flex;
        }
    }
    
}