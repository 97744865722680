.BrandItem {
    width: 100%;
    border-radius: 10px;
    height: 260px;
    display: flex;
    flex-direction: column;

    &__img {
        // flex: 1 0 auto;
        //height: 160px;
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 15px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: flex;
            pointer-events: none;
        touch-action: none;
        }
    }

    &__action {

        button {
            width: 100%;
        }
    }
}

